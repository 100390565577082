export interface SiteConfig {
  siteName: string;
  description: string;
  currentlyAt: string;
  socialLinks: {
    tiktok: string;
    youtube: string;
    instagram: string;
    facebook: string;
    linkedin: string;
  };
}

const siteConfig: SiteConfig = {
  siteName: 'GolfLive web portal',
  description: 'GolfLive - Golf Instruction Reinvented, Built for Your Journey',
  currentlyAt: 'Houston, Tx 77024',
  socialLinks: {
    tiktok: 'https://www.tiktok.com/@golfliveapp',
    youtube: 'https://www.youtube.com/@golfliveapp?si=4JvApoVeNMGcxnat',
    instagram:
      'https://www.instagram.com/golfliveapp/?igsh=MWpsYW02bThmZXB6MA%3D%3D',
    facebook: 'https://www.facebook.com/golfliveapp',
    linkedin: 'https://www.linkedin.com/company/golf-live',
  },
};

export default siteConfig;
