const MainLogoSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="226"
    height="40"
    viewBox="0 0 226 40"
    fill="none"
  >
    <path
      d="M63.4265 19.0756H73.1139V21.2681C73.1139 24.1572 72.5743 26.6047 71.5884 28.0615C70.0336 30.4453 67.017 31.7157 62.9508 31.7157C58.8845 31.7157 56.28 30.666 54.1856 28.4441C52.2187 26.3153 51.2622 23.647 51.2622 20.189C51.2622 12.8512 55.7699 8.27979 63.0096 8.27979C66.4088 8.27979 69.1997 9.10382 71.5198 10.85L69.9943 13.6115C68.3119 12.3117 65.8643 11.5465 63.3873 11.5465C58.5902 11.5465 55.5442 14.8475 55.5442 20.0566C55.5442 22.3423 56.0838 24.2798 57.1972 25.8052C58.497 27.6152 60.2776 28.4392 62.9753 28.4392C66.9778 28.4392 69.0084 26.6293 69.0084 23.1026V22.0529H63.4216V19.0658L63.4265 19.0756Z"
      fill="#028A19"
    />
    <path
      d="M102.612 19.9977C102.612 26.9824 97.9132 31.7157 90.9236 31.7157C83.934 31.7157 79.1418 27.0462 79.1418 19.8408C79.1418 16.7605 80.1915 13.8714 82.0652 11.8702C84.2577 9.48641 87.2105 8.27979 90.8942 8.27979C97.9132 8.27979 102.612 12.9787 102.612 19.9977ZM83.4288 19.8996C83.4288 25.1725 86.2541 28.4441 90.8893 28.4441C95.5245 28.4441 98.3203 25.1431 98.3203 19.9977C98.3203 14.8524 95.4313 11.5514 90.8893 11.5514C86.3473 11.5514 83.4288 14.823 83.4288 19.9046V19.8996Z"
      fill="#028A19"
    />
    <path
      d="M109.818 8.65747H113.948V28.0615H122.492V31.3331H109.823V8.65747H109.818Z"
      fill="#028A19"
    />
    <path
      d="M128.329 8.65747H140.841V11.9291H132.459V18.1829H140.145V21.4545H132.459V31.3282H128.329V8.65747Z"
      fill="#028A19"
    />
    <path
      d="M157.793 8.65747H161.923V28.0615H170.467V31.3331H157.798V8.65747H157.793Z"
      fill="#028A19"
    />
    <path d="M176.31 8.65747H180.44V31.3331H176.31V8.65747Z" fill="#028A19" />
    <path
      d="M186.467 8.65747H190.847L196.341 26.026L201.835 8.65747H206.215L198.151 31.4607H194.561L186.462 8.65747H186.467Z"
      fill="#028A19"
    />
    <path
      d="M212.282 8.65747H225.364V11.9291H216.314V17.9622H224.349V21.2338H216.314V28.0615H225.776V31.3331H212.277V8.65747H212.282Z"
      fill="#028A19"
    />
    <path
      d="M19.9976 0C8.95158 0 0 8.95156 0 19.9975C0 28.1545 4.88536 35.1686 11.8848 38.2784V10.4721C11.8848 10.3691 11.8946 10.271 11.9093 10.1729C12.0564 7.83323 13.8124 6.92091 15.9559 8.15205L32.623 17.7118C34.8646 18.9969 34.8646 21.0815 32.623 22.3715L15.9559 31.9313C15.8823 31.9755 15.8087 32.0147 15.7352 32.049V39.5438C17.1086 39.843 18.531 40 19.9927 40C31.0387 40 39.9903 31.0484 39.9903 20.0025C39.9903 8.95647 31.0387 0 19.9976 0Z"
      fill="#028A19"
    />
  </svg>
);

export { MainLogoSvg };
