'use client';

import { Spinner } from 'flowbite-react';
import React from 'react';

export const Loading: React.FC = () => {
  return (
    <div className="fixed z-50 inset-0 flex items-center justify-center bg-black bg-opacity-15">
      <Spinner color="success" size="xl" aria-label="Loading spinner" />
    </div>
  );
};
