'use client';

import Link from 'next/link';
import React from 'react';
import siteConfig from '@/config/site';
import { IFooter, INavItem } from '@/shared/types/dictionary';
import {
  FaceBookIcon,
  InstagramIcon,
  LinkedinIcon,
  TikTokIcon,
  YouTubeIcon,
} from '@/components/design/assets/icons/small-social-media.icon';
import { MainLogoSvg } from '@/components/design/assets/svg/index.svg';
import {
  AppleStoreIcon,
  GoogleStoreIcon,
} from '@/components/design/assets/icons/index.icon';

interface IProps {
  navbar: INavItem[];
  data: IFooter;
}

const FooterComponent: React.FC<IProps> = ({ navbar }) => {
  const usedNavbarNames = [
    'Home',
    'Privacy Policy',
    'Terms of service',
    'Contact',
  ];

  const filteredNavbar = navbar.filter(el => usedNavbarNames.includes(el.name));

  return (
    <section className="w-full bg-white py-8 px-4 md:py-16 md:px-16 lg:px-32">
      <div className="w-full flex flex-col space-y-8">
        <div className="flex flex-col md:flex-row justify-between items-center md:items-start space-y-8 md:space-y-0">
          <div className="flex flex-col text-center md:text-start mb-8">
            <div className="mb-8 flex justify-center md:justify-start">
              <MainLogoSvg />
            </div>
            <p className="mb-4 text-gray-700 leading-relaxed w-full md:w-[320px]">
              Golf Live revolutionizes golf instruction with its patented live,
              virtual video capture and replay.
            </p>
          </div>

          <div className="flex flex-col items-center md:items-start">
            <h3 className="text-green-600 mb-4">Get the app</h3>
            <div className="flex flex-col space-y-4">
              <div className="w-[160px] h-[50px]">
                <Link href='https://apps.apple.com/us/app/golf-live-virtual-lessons/id6475585606'>
                  <AppleStoreIcon />
                </Link>
              </div>
              <div className="w-[160px] h-[50px]">
                <Link href='https://play.google.com/store/apps/details?id=com.golfliveapp.app&hl=us'>
                  <GoogleStoreIcon />
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-center md:justify-start space-x-4">
          {filteredNavbar.map(item => (
            <Link
              key={item.id}
              href={`en${item.href}`}
              className="text-gray-800 hover:underline text-sm md:text-md font-semibold"
            >
              {item.name}
            </Link>
          ))}
        </div>

        <hr className="my-8" />

        <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
          <div>
            <p className="text-sm md:text-base">
              © 2025 Golf Live. All rights reserved.
            </p>
          </div>
          <div>
            <ul className="flex justify-center space-x-4">
              <li>
                <a
                  href={siteConfig.socialLinks.facebook}
                  target="_blank"
                  rel="noreferrer"
                  className="hover:opacity-75"
                >
                  <FaceBookIcon color={'#A1A1AA'} />
                </a>
              </li>
              <li>
                <a
                  href={siteConfig.socialLinks.instagram}
                  target="_blank"
                  rel="noreferrer"
                  className="hover:opacity-75"
                >
                  <InstagramIcon color={'#A1A1AA'} />
                </a>
              </li>
              <li>
                <a
                  href={siteConfig.socialLinks.linkedin}
                  target="_blank"
                  rel="noreferrer"
                  className="hover:opacity-75"
                >
                  <LinkedinIcon color={'#A1A1AA'} />
                </a>
              </li>
              <li>
                <a
                  href={siteConfig.socialLinks.tiktok}
                  target="_blank"
                  rel="noreferrer"
                  className="hover:opacity-75"
                >
                  <TikTokIcon color={'#A1A1AA'} />
                </a>
              </li>
              <li>
                <a
                  href={siteConfig.socialLinks.youtube}
                  target="_blank"
                  rel="noreferrer"
                  className="hover:opacity-75"
                >
                  <YouTubeIcon color={'#A1A1AA'} />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FooterComponent;
