import { Button, ButtonSizes, Spinner } from 'flowbite-react';
import { DynamicStringEnumKeysOf } from 'flowbite-react/dist/types/types';
import { FC, PropsWithChildren } from 'react';

interface IProps extends PropsWithChildren {
  isLoading: boolean;
  onClick?: () => void;
  className?: string;
  type?: 'button' | 'submit';
  disabled?: boolean;
  size?: DynamicStringEnumKeysOf<ButtonSizes>;
}

const LoadingButton: FC<IProps> = ({
  isLoading,
  onClick,
  children,
  className,
  type = 'submit',
  disabled = false,
  size,
}) => {
  return (
    <Button
      className={className}
      type={type}
      onClick={onClick}
      disabled={isLoading || disabled}
      size={size}
      color="success"
    >
      {isLoading ? (
        <div className="flex items-center justify-center">
          <Spinner size="sm" light={true} className="mr-2" />
          Loading...
        </div>
      ) : (
        children
      )}
    </Button>
  );
};

export default LoadingButton;
