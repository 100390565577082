import Link from 'next/link';
import ApplePay from '@/public/stores/with-color/apple-store-new.svg';
import GooglePlay from '@/public/stores/with-color/google-store-new.svg';

interface IProps {
  className?: string;
  withBg?: boolean;
}

export default function StoresComponent({ className }: IProps) {
  return (
    <div className={`${className}`}>
      <Link href="https://apps.apple.com/app/golf-live-virtual-lessons/id6475585606">
        <ApplePay
          className="h-10 w-[135px]"
          alt="Apple store icon"
          aria-label="Apple store icon"
        />
      </Link>
      <Link href="https://play.google.com/store/apps/details?id=com.golfliveapp.app">
        <GooglePlay
          className="h-10 w-[135px]"
          alt="Google play icon"
          aria-label="Google play icon"
        />
      </Link>
    </div>
  );
}
