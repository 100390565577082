'use client';
import Image from 'next/image';

export default function Logo() {
  return (
    <a href="/" className="-m-1.5 p-1.5">
      <Image
        width={132}
        height={66}
        className="h-auto w-[132px]"
        src="/main-page/gl-logo.png"
        alt="main-logo"
      />
    </a>
  );
}
