'use client';

import cn from 'classnames';

export default function SectionTitle({
  title,
  className,
}: {
  title: string;
  className?: string;
}) {
  return (
    <div
      className={cn(
        'primary mb-6 text-center text-4xl md:mb-12 lg:mb-16',
        className,
      )}
    >
      <h2>{title}</h2>
    </div>
  );
}
