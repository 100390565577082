import React from 'react';
import { Modal, Button } from 'flowbite-react'; // Імпортуємо компоненти з Flowbite

interface ServerErrorModalProps {
  isOpen: boolean;
  onClose: () => void;
  errorMessage?: string;
}

const ServerErrorModal: React.FC<ServerErrorModalProps> = ({
  isOpen,
  onClose,
  errorMessage,
}) => {
  return (
    <Modal show={isOpen} onClose={onClose} size="md" popup={true}>
      <Modal.Header />
      <Modal.Body>
        <div className="text-center">
          <h3 className="mb-5 text-lg font-normal text-gray-500">
            A server error has been encountered
          </h3>
          {errorMessage && (
            <p className="mb-4 text-sm text-red-500">{errorMessage}</p>
          )}
          <div className="flex justify-end gap-4">
            <Button color="failure" onClick={onClose}>
              Close
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ServerErrorModal;
