import { createApi } from '@reduxjs/toolkit/query/react';

import customFetchBase from '../customFetchBase';
import {IApiResponse} from "@/shared/types/app";


export const appApi = createApi({
  reducerPath: 'appApi',
  baseQuery: customFetchBase,
  endpoints: build => ({
    getAgreement: build.query<void, string>({
      query: key => ({
        url: '/app-storage/get/',
        method: 'GET',
        params: { key },
      }),
      transformResponse: (result: IApiResponse<any>) => {
        const { data } = result;

        return data;
      },
    }),
  }),
});

export const { useGetAgreementQuery } = appApi;
